<template>
  <div class="task-accessories-root-container">
    <div class="accessories-header-container">
      <span style="font-size: 23px; font-weight: bold"
        >全部附件{{ accessories.length }}个</span
      >
      <Icon
        @click="upload"
        type="ios-cloud-upload-outline"
        style="cursor: pointer"
        :size="30"
      />
    </div>

    <accessories-list :dataSource="accessories" :showType="false"/>
    <accessories-drawer ref="accessoriesDrawer" @add="add" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import AccessoriesList from "../../components/List/AccessoryList";
import AccessoriesDrawer from "../../components/Drawer/AccessoriesDrawer";
import moment from 'moment'
export default {
  components: {
    "accessories-list": AccessoriesList,
    "accessories-drawer": AccessoriesDrawer,
  },
  data() {
    return {
      taskId: 0,
      accessories: [],
    };
  },
  mounted() {
    this.taskId = this.$router.currentRoute.params.tid;
    this.refresh();
  },
  methods: {
    ...mapActions({
      taskGetTaskAccessoriesAction: "taskGetTaskAccessories",
      taskAddAccessoriesAction: "taskAddAccessories",
    }),
    refresh() {
      this.taskGetTaskAccessoriesAction({
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      })
        .then((res) => {
          for (let item of res) {
            item.create_time = moment(item.create_time).format(
              "YYYY/MM/DD HH:mm"
            );
          }
          this.accessories = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    upload() {
      this.$refs.accessoriesDrawer.open();
    },
    add(acc) {
      let params = Object.assign(acc, {
        employer_id: localStorage.getItem("employer_id"),
        task_id: this.taskId,
      });
      this.taskAddAccessoriesAction(params)
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.task-accessories-root-container {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
}
.accessories-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.accessories-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
  padding-bottom: 10px;
}
.accessories-main-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  min-height: 50px;
}
.accessories-item-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
}
</style>